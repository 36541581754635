import React, { useState } from "react";
import Layout from "../../components/Layout/layout";
import SEO from "../../components/seo";
import EmployeeAffiliateForm from "../../page-components/employee-affiliates/form";
import bg from "../../images/svg/vector-14.svg";
import { queryByEmail } from "../../utils/firebase/employees";
import ogImage from "../../images/global_assets/og-image.png";

const AffiliatePage = () => {
  const [step, setStep] = useState(1);
  const [user, setUser] = useState(null);
  const [userLoaded, setUserLoaded] = useState(false);

  const getUser = async (email) => {
    const dbUser = await queryByEmail(email);
    setUser(dbUser);
    setUserLoaded(true);
  };

  return (
    <Layout
      transparentHeader
      isLanding
      useNewLPHeader
      className="non-fixed-width"
    >
      <SEO
        title="SpotOn Employee Affiliate"
        description="Do you have a friend or family member who owns a business and would like to refer them to SpotOn? Perhaps you have built a friendly relationship with the businesses you frequent in your community (restaurants, local coffee shop, barber, mechanic etc). Launching this month, you can now earn up to $1000 when you refer a business to SpotOn upon activation."
        image={`https://spoton.com/${ogImage}`}
      />

      <img src={bg} alt="background" className="absolute top-0 right-0 " />

      <EmployeeAffiliateForm
        step={step}
        setStep={setStep}
        user={user}
        setUser={setUser}
        getUser={getUser}
        userLoaded={userLoaded}
        setUserLoaded={setUserLoaded}
      />
    </Layout>
  );
};

export default AffiliatePage;
