import {
  collection,
  getDocs,
  query,
  where,
  doc,
  getDoc,
  setDoc,
} from "firebase/firestore";
import { db, signAnonymously } from "./firestore";

const COLLECTION = "employees";
const EMAIL_PROP = "WorkContact:WorkEmail";

const USER_INFO_READ_COLLECTION = "employees-referral-info-read";

const queryEmployeeByEmail = async (email = "") => {
  const collectionRef = collection(db, COLLECTION);
  const q = query(collectionRef, where(EMAIL_PROP, "==", email.toLowerCase()));
  const qSnapshot = await getDocs(q);

  return qSnapshot.size > 0 ? qSnapshot.docs[0].data() : null;
};

export const queryByEmail = async (email) => {
  return signAnonymously()
    .then(() => {
      return queryEmployeeByEmail(email);
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode);
      console.log(errorMessage);
    });
};

export const queryEmployeeCheckedInfo = async (email = "") => {
  const snap = await getDoc(doc(db, USER_INFO_READ_COLLECTION, email));
  return snap.exists();
};

export const setUserReadInfo = async (email = "") => {
  try {
    await setDoc(doc(db, USER_INFO_READ_COLLECTION, email), {});
    return true;
  } catch (err) {
    return false;
  }
};
