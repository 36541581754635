import React, { useState, useEffect } from "react";
import { bool, func, number, object } from "prop-types";
import { isMobile } from "react-device-detect";
import { navigate } from "gatsby";
import Cookies from "js-cookie";
import Heading from "../../components-v2/Base/HeadingBuilder";
import {
  campaignInfo,
  stepTitles,
  subTitle,
  title,
} from "../../data/employee-referral";
import CtaSecondary from "../../components-v2/Base/CtaSecondary";
import CtaPrimary from "../../components-v2/Base/CtaPrimary";
import Step1 from "./step-1";
import Step2 from "./step-2";
import Step3 from "./step-3";
import {
  emailValidationRegExp,
  phoneValidationRegExp,
  zipValidationRegExp,
} from "../../constants";
import lightBulb from "../../images/svg/icons/lightbulb.svg";
import closeIcon from "../../images/svg/close-icon.svg";
import { submitEmployeeReferralForm } from "../../utils/firebase/forms";

const EmployeeAffiliateForm = ({
  step,
  setStep,
  getUser,
  user,
  setUser,
  userLoaded,
  setUserLoaded,
}) => {
  const [repEmail, setRepEmail] = useState("");
  const [userName, setUserName] = useState([]);
  const [userEmail, setUserEmail] = useState("");

  const [prospectFirstName, setProspectFirstName] = useState("");
  const [prospectLastName, setProspectLastName] = useState("");
  const [prospectEmail, setProspectEmail] = useState("");
  const [prospectPhone, setProspectPhone] = useState("");
  const [prospectCompanyName, setProspectCompanyName] = useState("");
  const [prospectZip, setProspectZip] = useState("");
  const [prospectLocationsNumber, setProspectLocationsNumber] = useState("");

  const [additionalInfo, setAdditionalInfo] = useState("");

  const [showErrors, setShowErrors] = useState(false);
  const [loading, setLoading] = useState(false);

  const [step2Errors, setStep2Errors] = useState({
    firstName: true,
    lastName: true,
    email: true,
    phone: true,
    companyName: true,
    zip: true,
    locations: true,
  });

  const [step3Errors, setStep3Errors] = useState({
    additionalInfo: true,
  });

  const [step2loading, setStep2Loading] = useState(true);
  const [infoRead, setInfoRead] = useState(false);

  const onSubmit = async () => {
    setLoading(true);

    // const address = zipCodesMap.get(prospectZip);
    const [userFirstName, userLastName] =
      user.PayrollName.split(", ").reverse();

    const formData = {
      referrer_first_name: userFirstName,
      referrer_last_name: userLastName,
      referrer_email: user["WorkContact:WorkEmail"],
      employee_id: user.PositionID,
      referrer_type: "Employee",
      firstname: prospectFirstName,
      lastname: prospectLastName,
      company: prospectCompanyName,
      // city: address.city,
      // state: address.state,
      zipcode: prospectZip,
      phone: prospectPhone,
      email: prospectEmail,
      lead_notes: additionalInfo,
      location: prospectLocationsNumber,
      gclid: Cookies.get("gclid") || "",
      utm_source: Cookies.get("utmSource") || "organic",
      utm_medium: Cookies.get("utmMedium") || "",
      utm_campaign: Cookies.get("utmCampaign") || "",
      utm_content: Cookies.get("utmContent") || "",
      utm_term: Cookies.get("utmTerm") || "",
    };

    const result = await submitEmployeeReferralForm(formData);

    if (result) {
      setLoading(false);
      window.sessionStorage.setItem("Name", userFirstName);
      navigate("/employee-referral/thank-you");
    } else {
      setLoading(false);
    }

    // axios({
    //   method: "post",
    //   url: `${hubSpotFormSubmitBaseUrl}/${formId}`,
    //   headers: { "Content-Type": "application/json" },
    //   data: JSON.stringify(formData),
    // })
    //   .then(() => {
    //     window.sessionStorage.setItem("Name", userName.join(" "));
    //     const thankYouUrl = "/employee-referral/thank-you";
    //     navigate(thankYouUrl);
    //   })
    //   // eslint-disable-next-line consistent-return
    //   .catch((error) => {
    //     setLoading(false);
    //     // TODO: Handle the errors properly
    //     if (error.response) {
    //       if (
    //         error?.response?.data?.errors[0]?.errorType.toString() ===
    //         "INVALID_EMAIL"
    //       ) {
    //         setLoading(false);
    //         // eslint-disable-next-line no-alert
    //         return alert(
    //           `Looks like the email ${prospectEmail} can not be validated.
    //             Please use a valid email.`
    //         );
    //       }
    //     } else if (error.request) {
    //       // eslint-disable-next-line no-console
    //       console.log(error.request);
    //     } else {
    //       // eslint-disable-next-line no-console
    //       console.log("Error", error.message);
    //     }
    //   });
  };

  useEffect(() => {
    if (isMobile) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [step]);

  useEffect(() => {
    if (step === 2) {
      const hasErrors = Object.values(step2Errors).includes(true);
      if (!hasErrors) {
        setStep(3);
        setShowErrors(false);
      }
    }

    if (step === 3) {
      const hasErrors = Object.values(step3Errors).includes(true);
      if (!hasErrors) {
        setShowErrors(false);
        if (!loading) {
          onSubmit();
        }
      }
    }
  }, [step2Errors, step3Errors]);

  useEffect(() => {
    if (step === 1) {
      if (repEmail && userLoaded && user) {
        setStep(2);
      }
      setShowErrors(userLoaded && !user);
    }
  }, [step, user, userLoaded]);

  useEffect(() => {
    if (user) {
      const name = user.PayrollName?.split(", ").reverse();
      setUserName(name || []);
      setUserEmail(user["WorkContact:WorkEmail"]);
    }
  }, [user]);

  const checkStep2Errors = () => {
    setStep2Errors({
      firstName: !prospectFirstName.trim().length > 0,
      lastName: !prospectLastName.trim().length > 0,
      email: !emailValidationRegExp.test(prospectEmail),
      phone: !phoneValidationRegExp.test(prospectPhone),
      companyName: !prospectCompanyName.trim().length > 0,
      zip:
        !prospectZip.trim().length > 0 ||
        !zipValidationRegExp.test(prospectZip),
      locations: !prospectLocationsNumber.trim().length > 0,
    });
  };

  const checkStep3Errors = () => {
    setStep3Errors({
      additionalInfo: !additionalInfo.trim().length > 0,
    });
  };

  const onNextClick = async () => {
    if (step === 1) {
      await getUser(repEmail);
    }
    if (step === 2) {
      checkStep2Errors();
      setShowErrors(true);
    }
    if (step === 3) {
      checkStep3Errors();
      setShowErrors(true);
    }
  };

  const onBackClick = () => {
    if (step === 2) {
      setUser(null);
      setUserLoaded(false);
    }
    setStep((st) => st - 1);
  };

  const stepBaseStyles =
    "text-[18px] w-[32px] h-[32px] rounded-full flex items-end justify-center mx-3 border border-solid";
  const stepCompleteStyles = "text-white bg-primary border-primary";
  const stepIncompleteStyles = "text-black-400 border-black-400";

  const step1Props = {
    repEmail,
    setRepEmail,
    showErrors,
  };

  const step2Props = {
    userEmail,
    loading: step2loading,
    setLoading: setStep2Loading,
    infoRead,
    setInfoRead,
    prospectFirstName,
    setProspectFirstName,
    prospectLastName,
    setProspectLastName,
    prospectEmail,
    setProspectEmail,
    prospectPhone,
    setProspectPhone,
    prospectCompanyName,
    setProspectCompanyName,
    prospectZip,
    setProspectZip,
    prospectLocationsNumber,
    setProspectLocationsNumber,
    showErrors,
    errors: step2Errors,
  };

  const step3Props = {
    additionalInfo,
    setAdditionalInfo,
    showErrors,
    errors: step3Errors,
  };

  const stepsMap = {
    1: <Step1 {...step1Props} />,
    2: <Step2 {...step2Props} />,
    3: <Step3 {...step3Props} />,
  };

  const stepToTitleMap = {
    1: stepTitles.step1,
    2: `Welcome, ${userName[0]}`,
    3: stepTitles.step3,
  };

  return (
    <>
      <section
        className={`
      bg-black w-full mx-auto pt-40
      pb-64 md:pb-48 lg:pb-48 2xl:pb-80
      mb-20
      `}
      >
        <div className="max-w-1440 w-full mx-auto px-4 lg:px-10">
          <Heading
            level={1}
            injectionType={isMobile ? 2 : 1}
            withBlueSymbol
            className="text-white text-center relative"
          >
            {title}
          </Heading>
          <p className="relative mx-auto text-center text-black-500 text-p max-w-[630px]">
            {subTitle}
          </p>

          <div className="flex flex-row justify-center my-8 md:my-10">
            <div className={`${stepBaseStyles} ${stepCompleteStyles}`}>1</div>

            <span
              className="mb-[16px] w-[70px] md:w-[100px]"
              style={{ borderBottom: "1px dashed #8A95A6" }}
            />

            <div
              className={`${stepBaseStyles} ${
                step > 1 ? stepCompleteStyles : stepIncompleteStyles
              }`}
            >
              2
            </div>

            <span
              className="mb-[16px] w-[70px] md:w-[100px]"
              style={{ borderBottom: "1px dashed #8A95A6" }}
            />

            <div
              className={`${stepBaseStyles} ${
                step === 3 ? stepCompleteStyles : stepIncompleteStyles
              }`}
            >
              3
            </div>
          </div>

          {step === 2 && (
            <CtaPrimary
              title="Learn more about the program"
              className="mx-auto"
              onClick={() => setInfoRead(false)}
            />
          )}
        </div>

        <div
          style={{
            perspective: "3000px",
          }}
          className="relative"
        >
          <section
            className={`absolute top-0 left-0 right-0 bottom-0
              text-black-300 transition transition-all duration-300
            `}
            style={{
              transformStyle: "preserve-3d",
              transform: !step2loading && !infoRead ? "" : "rotateY(90deg)",
              visibility: !step2loading && !infoRead ? "visible" : "hidden",
            }}
          >
            <div
              className={`
            bg-white rounded-lg
            mx-4 lg:mx-40 2xl:mx-64
            px-6 lg:px-10 2xl:px-24
            py-6 lg:py-10
          `}
              style={{
                boxShadow: "0px 32px 60px rgba(0, 0, 0, 0.07)",
                margin: "auto",
                maxWidth: "1000px",
              }}
            >
              <div className="flex justify-end">
                <button
                  className="cursor-pointer 2xl:-mr-12"
                  type="button"
                  onClick={() => setInfoRead(true)}
                  style={{
                    background: "none",
                    border: "none",
                    outline: "none",
                  }}
                >
                  <img src={closeIcon} alt="close icon" />
                </button>
              </div>
              <div className="w-full flex justify-center mb-4">
                <img src={lightBulb} alt="light bulb" className="" />
              </div>
              <Heading
                level={3}
                injectionType={3}
                withBlueSymbol
                symbol="?"
                className="mx-auto text-center"
              >
                {campaignInfo.title}
              </Heading>
              {/* <p className="text-center">{campaignInfo.info}</p> */}

              <ul>
                {campaignInfo.bullets.map((bullet) => (
                  <li
                    className=""
                    key={bullet}
                    dangerouslySetInnerHTML={{ __html: bullet }}
                  />
                ))}
              </ul>
            </div>
          </section>

          <section
            className="mt-10 md:mt-20 transition transition-all duration-300"
            style={{
              transformStyle: "preserve-3d",
              transform:
                (!step2loading && infoRead) || step !== 2
                  ? ""
                  : "rotateY(-90deg)",
              visibility:
                (!step2loading && infoRead) || step !== 2
                  ? "visible"
                  : "hidden",
            }}
          >
            <div
              className={`
            bg-white rounded-lg
            mx-4 lg:mx-40 2xl:mx-64
            px-6 lg:px-10 2xl:px-24
            py-6 lg:py-10
          `}
              style={{
                boxShadow: "0px 32px 60px rgba(0, 0, 0, 0.07)",
                margin: "auto",
                maxWidth: "1000px",
              }}
            >
              <Heading
                level={3}
                injectionType={3}
                className="text-black text-center"
                withBlueSymbol
              >
                {stepToTitleMap[step]}
              </Heading>

              {stepsMap[step]}

              <div className="flex flex-col-reverse md:flex-row items-center gap-x-8 justify-center mt-4 md:mt-10">
                {step > 1 && (
                  <CtaSecondary
                    className="w-full md:w-auto justify-center my-4 md:my-auto"
                    title="Go back"
                    reverseIcon
                    large
                    onClick={onBackClick}
                  />
                )}
                {step <= 3 && (
                  <CtaPrimary
                    className="w-full md:w-auto justify-center my-4 md:my-auto"
                    title={step < 3 ? "Next step" : "Submit"}
                    onClick={onNextClick}
                    disabled={loading}
                  />
                )}
              </div>
            </div>
          </section>
        </div>
      </section>
    </>
  );
};

EmployeeAffiliateForm.propTypes = {
  step: number.isRequired,
  setStep: func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  user: object,
  setUser: func.isRequired,
  getUser: func.isRequired,
  userLoaded: bool.isRequired,
  setUserLoaded: func.isRequired,
};

EmployeeAffiliateForm.defaultProps = {
  user: null,
};

export default EmployeeAffiliateForm;

export const labelStyles = "text-black-100 mb-1 font-[15px]";
export const stepStyles = `
      relative grid grid-cols-1 md:grid-cols-2
      gap-x-8
      gap-y-2
    `;
export const errorStyles = "text-danger text-[12px] mt-1";
